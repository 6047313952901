import React from 'react'
import css from "./Cards.module.css"
import { useDispatch, useSelector } from 'react-redux'
import Card from '../Card/Card'
import { useEffect } from 'react'
import { useState, useRef } from 'react'


const Cards = () => {

   const dispatch=useDispatch()

  useEffect(() => {
   dispatch({type:"trending"})
  }, [])
     
  const container = useRef(null);

  const select= useSelector((state)=>state.data)
  
  const [start, setStart] = useState(true)
  const [end, setEnd] = useState(false)
 const scrollAmount=200

 const updateButton=()=>{
  if(container.current){
    setStart(container.current.scrollLeft===0)
    setEnd(container.current.scrollLeft + container.current.clientWidth>=container.current.scrollWidth)
  }
}

 const scrollLeft = () => {
  if (container.current) {
    container.current.scrollBy({
      left: -scrollAmount,
      behavior: 'smooth'
    });
    updateButton()
  }
};

const scrollRight = () => {
  if (container.current) {
    container.current.scrollBy({
      left: scrollAmount,
      behavior: 'smooth'
    });
    updateButton()
  }
};

useEffect(() => {
     updateButton()
  
}, [])






  return (
  <div className={css.container}>
    <div className={css.head}>
            <span>Trendings</span>
    </div>
    <div className={css.mainCard}>
      
         <div className={css.scroller}>
          <button className={css.scroll} onClick={scrollLeft} disabled={start}>&lt;</button>
          <button className={css.scroll} onClick={scrollRight} disabled={end}>&gt;</button>
         </div>
    <div className={css.card} ref={container}>
         {select.map((element)=>(
           <Card element={element}/>
          ))}
          </div>
    </div>
  </div>
  )
}

export default Cards