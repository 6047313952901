import './App.css';
import { BrowserRouter,Routes, Route } from 'react-router-dom';
import Layout from './Pages/Layout/Layout'; 
import Home from './Pages/Home/Home';
import Explore from './Pages/Explore/Explore';
import { useState } from 'react';
import Content from './Pages/Content/Content';


function App() {
 const storage= localStorage
  
  return (
    <div className="App">
         <BrowserRouter>
         <Routes>
        <Route path='/' element={<Layout storage={storage}/>}>
           <Route path='/home' element={<Home storage={storage}/>}/>
           <Route path='/explore' element={<Explore storage={storage} />}/>
           <Route path="/content/:id" element={<Content/>}/>
         
          
        </Route>
         </Routes>
         </BrowserRouter>
    </div>
  );
}

export default App;
