import React, { useEffect } from "react";
import css from "./Card.module.css";
import { fixed } from "../../Data/Data";
import { FaRegThumbsUp } from "react-icons/fa";
import { FaThumbsUp } from "react-icons/fa";
import { useState } from "react";
import { IoEyeSharp } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { Time } from "../../Data/Data";

const Card = ({ element }) => {
  const [liked, setLiked] = useState(false);


  const dispatch = useDispatch(); 

  const time = element.publishDate;
  // const date = new Date(time);
  // const options = { year: "numeric", month: "long", day: "numeric" };
  // const formattedDate = date.toLocaleDateString(undefined, options);

  const formattedDate= Time(time)



// useEffect(() => {
//     titleReturned()

// }, [element])

 
const titleReturned=()=>{
  
   if(element.title.length<=40){
    return element.title
   }
   else{
   return element.title.slice(0,30)+"..." 
   }
}



  const handleLikeClick = async() => {
    setLiked(prevLiked => !prevLiked);
        try{

          // If not liked, dispatch like_increment
          if (!liked) {
             dispatch({ type: "like_increment", payload: element.id });
          }
          // If liked, dispatch like_decrement
          else {
              dispatch({ type: "like_decrement", payload: element.id });
          
          }
          console.log(element.likes)
        }catch(error){
          console.error(error)
        }
      

        
  };

  return (
    <div className={css.container}>
      {/* </div> */}
      {/* <div className={css.head}> */}
      <NavLink to={`/content/${element.id}`}  className={css.head}>
        <img src={element.image} />
      </NavLink>
      <div className={css.content}>
        <div className={css.writing}>
          {/* <span>{titleReturned()}</span> */}
          <NavLink to={`/content/${element.id}`} className={css.span}>{titleReturned()}</NavLink>
        </div>
        <div className={css.time}>
          <span>{formattedDate}</span>
        </div>
      </div>

      <div className={css.views}>
        <div className={css.eyes}>
          <span>
            <IoEyeSharp />
          </span>
          <span>{fixed(element.views)}</span>
        
        </div>

        <div className={css.likes}>
        <span
        onClick={() => {
              handleLikeClick()
        }}
          >
          {liked ? <FaThumbsUp /> : <FaRegThumbsUp />}
        </span>
         <span>
          {fixed(element.likes)}
          </span>
          </div>
      </div>

      <div className={css.button}>
         <NavLink to={`/content/${element.id}`} className={css.btn}>Read More...</NavLink>
      </div>
    </div>
  );
};

export default Card;
