import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import css from "./Content.module.css";
import { Time } from "../../Data/Data";
import { isElementType } from "@testing-library/user-event/dist/utils";
import ReactECharts from "echarts-for-react";
import { color } from "echarts";
import { FaBookmark, FaRegThumbsUp } from "react-icons/fa";
import { FaThumbsUp } from "react-icons/fa";
import { IoEyeSharp } from "react-icons/io5";
import { fixed } from "../../Data/Data";
import { CiBookmark } from "react-icons/ci";
import { PiMessengerLogoDuotone } from "react-icons/pi";
import { FaBookBookmark } from "react-icons/fa6";
import { RiMessengerLine } from "react-icons/ri";
import { useRef } from "react";

const Content = () => {
  const { id } = useParams();
  const apiKey="AIzaSyBBLx975D9V_RUwgy0r7U8Zn-sxkxz4trA"
  const { GoogleGenerativeAI } = require("@google/generative-ai");
  const genAI = new GoogleGenerativeAI(apiKey);

  const data = useSelector((state) => state.data);
  const [content, setContent] = useState([]);
  const [date, setDate] = useState(null);
  const [timeData, setTimeData] = useState();
  const [views, setViews] = useState([]);
  const [day, setDay] = useState();
  const [liked, setLiked] = useState(false);
  const [book, setBook] = useState(false);
  const [input, setInput] = useState("")
  const [opened, setOpened] = useState(false)
  const [chat, setChat] = useState([])
  const [response, setResponse] = useState()
  const chatScroll = useRef()



  const time = useSelector((state) => state.interval);
  const dispatch = useDispatch();

  const selected = (category) => {
    if (category) {
      const selectedCategory = category.filter(
        (element, key) => element.length <= 10
      );

      if (!selectedCategory || selectedCategory.length < 2) {
        return category.map((element, key) => {
          if (key === 1 && element.length > 10) {
            return element.slice(0, 9) + "...";
          } else {
            return element;
          }
        });
      } else {
        return selectedCategory;
      }
    }
  };
  useEffect(() => {
    dispatch({ type: "explore" });
    dispatch({ type: "storenon" });
    dispatch({ type: "views", payload: id });
    dispatch({ type: "dateViews", payload: id });
    console.log("Hey");
  }, []);

  useEffect(() => {
    // console.log("The data is", data)
    if (id && data.length > 0) {
      const filteredContent = data.filter((element) => {
        return element.id === id;
      });

      if (filteredContent.length > 0) {
        const selectedData = filteredContent.map((element) => {
          const filteredCategory = element.category.slice(0, 2);

          return {
            ...element,
            category: selected(filteredCategory),
          };
        });
        setContent(selectedData);
      }
    } else {
      console.log("No data");
    }
  }, [id, data]);

  useEffect(() => {
    //  console.log("The content is",content)
    if (content && content.length > 0) {
      const MyDate = content.reduce((accumulator, currentvalue) => {
        return Time(currentvalue.publishDate);
      }, 0);
      setDate(MyDate);
    }
  }, [content]);

  useEffect(() => {
    const selectedTime = time.filter((element) => element.id === id);
    if (selectedTime) {
      setTimeData(selectedTime);
    }
  }, [time]);

  useEffect(() => {
    if (timeData) {
      const filteredTime = timeData
        .map((element) => {
          return element.views.slice(-10);
        })
        .flat();
      console.log(filteredTime);
      const day = filteredTime.map((item) => item.date);
      setDay(day);
      const views = filteredTime.map((item) => item.views);
      setViews(views);
      console.log("The views would be", views);
    }
  }, [timeData]);

  const option = {
    title: {
      text: "Views from last 10 days",
      x: "center",
      textStyle: {
        color: "#fe904c", // Set the title color here
      },
    },
    xAxis: {
      type: "category",
      data: day,
    },
    yAxis: {
      type: "value",
      min: Math.min(...views),
      max: Math.max(...views) + 100,
      formatter: (value) => {
        if (value > 1000000) {
          return "More than a million";
        }
        if (value < Math.min(...views) - 100) {
          return `Less than ${Math.min(...views)}`;
        }
      },
    },
    series: [
      {
        data: views,
        type: "bar",
        itemStyle: {
          color: (params) => {
            // First 5 bars will have one color, the next 5 bars will have another color
            return params.dataIndex < 5 ? "#5470C6" : "#91CC75";
          },
        },
        smooth: true,
      },
    ],
    tooltip: {
      trigger: "item",
      axisPointer: {
        type: "shadow",
      },
      formatter: (prop) => {
        return `${prop.marker} views on ${day[prop.dataIndex].slice(0, -4)}: ${
          prop.value
        }`;
      },
      textStyle: {
        color: "grey", // Set the title color here
      },
    },
    toolbox: {
      show: true,
      feature: {
        saveAsImage: {
          title: "Download",
          name: "views",
          type: "png",
          pixelRatio: 2,
        },
      },
    },
  };

  const description = () => {
    return content.map((element) => {
      const first = element.content.split(" ");
      const twenty = first.slice(0, 100); //Later on make it to count full stop and at 4th full stop stop getting
      const remains = first.slice(100);

      twenty.push("-");

      return (
        <div className={css.focus}>
          <div className={css.firstPara}>
            {twenty.map((item, index) => (
              <span className={css.holders}>{item} </span>
            ))}
          </div>
          <div className={css.img}>
            <img src={element.image} alt="" />
          </div>
          <div className={css.remains}>
            {remains.map((item) => (
              <span className={css.holders}>{item} </span>
            ))}
          </div>
        </div>
      );
    });
  };

  const loved = () => {
    setLiked(!liked);
    if (liked) {
      dispatch({ type: "like_increment" });
    } else {
      dispatch({ type: "like_decrement" });
    }
  };
  const favour = () => {
    setBook(!book);
  };

  const generate=(()=>{
    var i=-1;
   return ()=>{
       i=i+1;
       return i;
    }
  })()

  const chatSubmit = async(e) => {
    const InpCopy=input;
    setInput("")
    e.preventDefault()  

    if(InpCopy){
   
     

     const modelChat={id:generate, question:InpCopy, answer:"..."}
      setChat((chat)=>[...chat,modelChat])

      console.log("The new ... chat is",chat)
      // console.log(InpCopy)
      const model = genAI.getGenerativeModel({ model: "gemini-1.5-flash"});
      // const prompt = "Write a story about a magic backpack." 
      const result = await model.generateContent(InpCopy);
      const response = await result.response;
      const text = await response.text();
      setResponse(text)
      // console.log(text); 
      
    
    }
  }

  useEffect(() => {
    if(response){
      const updatedChat = chat.map((item) =>
        item.answer === "..."? { ...item, answer: response } : item)
 
    console.log("The real answer to it is",updatedChat)
    setChat(updatedChat)
    
    }
      
    
  }, [response])

  useEffect(() => {
   if(chatScroll.current){
    chatScroll.current.scrollTop=chatScroll.current.scrollHeight
   }
  }, [chat])
  
  

  const keyDown=(e)=>{
    if(e.key==="Enter" && e.shiftKey){
      e.preventDefault()
      if(input){
        console.log("I can see")
      const {selectionStart, selectionEnd}=e.target;
 
       const newInp= input.slice(0,selectionStart)+"\n"+input.slice(selectionEnd)
       setInput(newInp)
      }
    
    }
    else if(e.key==="Enter"){
      e.preventDefault()
      
      chatSubmit(e)

    }
  }

  const funcChange=(e)=>{
       setInput(e.target.value)
  }
  return (
    <div className={css.container}>
      {content &&
        content.map((element) => (
          <div className={css.body}>
            <div className={css.head}>
              <div className={css.left}>
                <span>{date}</span>
                {element.trending ? (
                  <span>Trending</span>
                ) : (
                  <span>Underrated</span>
                )}
              </div>

              <div className={css.centre}>
                <span>{element.title}</span>
              </div>
              <div className={css.right}>
                <div className={css.author}>
                  <span>{element.author}</span>
                </div>
                <div className={css.rightLow}>
                  <span>Categories: </span>
                  {element.category.map((element) => (
                    <span>{element}</span>
                  ))}
                </div>
              </div>
            </div>
            <hr />

            <div className={css.main}>{description()}</div>
            <hr />

            <div className={css.stats}>
              <div className={css.charts}>
                <ReactECharts
                  option={option}
                  theme={{
                    color: [
                      "#fe904c",
                      "#2f4554",
                      "#61a0a8",
                      "#d48265",
                      "#91c7ae",
                    ],
                  }}
                />
              </div>
              <div className={css.achieve}>
                <div className={css.acLeft}>
                  <div className={css.views}>
                    <span>
                      {" "}
                      <IoEyeSharp size={30} />{" "}
                    </span>
                    <span>{fixed(element.views)}</span>
                  </div>
                  <div className={css.likes}>
                    <span
                      onClick={() => {
                        loved();
                      }}
                    >
                      {liked ? (
                        <FaThumbsUp size={30} />
                      ) : (
                        <FaRegThumbsUp size={30} />
                      )}
                    </span>
                    <span>{fixed(element.likes)}</span>
                  </div>
                </div>
                <div className={css.acRight}>
                  <span onClick={()=>{
                    setOpened(!opened)
                  }}>{opened?
                    <PiMessengerLogoDuotone size={30} />:<RiMessengerLine size={30}/>
                    }
                  </span>
                  <span
                    onClick={() => {
                      favour();
                    }}
                  >
                    {book ? <FaBookmark size={30} /> : <CiBookmark size={30} />}
                  </span>
                </div>
              </div>
              <div className={opened?`${css.message}`:`${css.close}`}>
                <div className={css.containMessage} ref={chatScroll}>
                  {chat &&
                    chat.map((element) => (
                      <div className={css.web}>
                        <div className={css.webTop}>
                          <span>{element.question}</span>
                        </div>
                        <div className={css.webDown}>
                          <span>{element.answer}</span>
                        </div>
                      </div>
                    ))}
                  {chat.length===0 && <div className={css.chatTruth}>
                  <span>Ask something!</span>
                  <span>Please consider typing something and submitting in order to establish a communication.</span>
                    </div>}
                </div>
                <div className={css.msgInput}>
                  <div className={css.inpMessage}>
                    <form className={css.input} onSubmit={chatSubmit}>
                        <textarea value={input} type="text" onChange={funcChange} placeholder="Enter the question" onKeyDown={keyDown} />
                         <div className={css.myButton}>
                        <button type="submit">&#8679;</button>
                         </div>
                    </form>
                  
                
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default Content;
