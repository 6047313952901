const generative = () => {
  let i = 1001; // Start from 1001, assuming 1000 is taken
  return () => {
    const iterated = JSON.stringify(i++);
    return iterated;
  };
};
//This worked bu each function getting an id of 2
const generate = generative();

const lorem = () => {
  return `Lorem ipsum dolor sit amet,
   consectetur adipiscing elit, sed do
    eiusmod tempor incididunt ut labore et dolore magna aliqua. Viverra
     suspendisse potenti nullam ac tortor vitae purus faucibus. 
     Nisl condimentum id venenatis a condimentum vitae sapien. 
     Sit amet massa vitae tortor condimentum lacinia quis vel. 
     Sit amet nisl purus in mollis nunc. Id aliquet lectus proin 
     nibh nisl condimentum. Libero volutpat sed cras ornare arcu 
     dui. Feugiat pretium nibh ipsum consequat nisl. Pharetra
      diam sit amet nisl suscipit adipiscing bibendum est.
       Faucibus in ornare quam viverra orci sagittis eu. 
       Dignissim cras tincidunt lobortis feugiat vivamus at.
        Hendrerit gravida rutrum quisque non tellus orci ac 
        auctor. Maecenas ultricies mi eget mauris pharetra et.
        vcAmet dictum sit amet justo donec enim diam vulputate 
        ut. Id volutpat lacus laoreet non curabitur. In ornare 
        quam viverra orci sagittis. Mauris augue neque gravida in. V
        iverra suspendisse potenti nullam ac tortor vitae purus faucibus. At in tellus
         integer feugiat scelerisque varius. Ligula ullamcorper malesuada proin libero
          nunc consequat interdum. Vulputate odio ut enim blandit volutpat maecenas
           volutpat blandit. Egestas quis ipsum suspendisse ultrices gravida. Malesuada
            fames ac turpis egestas sed tempus urna et pharetra. Nibh tellus molestie nunc
             non blandit. Facilisi nullam vehicula ipsum a arcu cursus. Varius morbi enim nunc faucibus a pellentesque sit amet porttitor. Tortor dignissim convallis aenean et tortor at. 
             Ultrices vitae auctor eu augue ut lectus.Justo eget magna fermentum iaculis. Sit amet nulla facilisi morbi tempus. Lectus arcu bibendum at varius vel pharetra vel. Et netus et malesuada fames ac turpis egestas maecenas pharetra. Ipsum faucibus vitae aliquet nec ullamcorper. Venenatis cras sed felis eget velit aliquet sagittis id. Amet nisl suscipit adipiscing bibendum est ultricies integer quis. Congue quisque egestas diam in arcu cursus euismod. Augue mauris augue neque gravida in fermentum et. Integer enim neque volutpat ac tincidunt vitae semper quis lectus. Suspendisse potenti nullam ac tortor vitae purus faucibus. Neque egestas congue quisque egestas diam in arcu. Pulvinar mattis nunc sed blandit. Eget lorem dolor sed viverra. Tincidunt nunc pulvinar sapien et ligula ullamcorper
              malesuada proin libero. Feugiat pretium nibh ipsum consequat nisl vel. Enim blandit volutpat maecenas volutpat.`
};

export const NewData = [
  {
    title: "Neuroscience Breakthroughs in 2024",
    content:
      "Recent advancements in neuroscience have uncovered new insights into brain function and neuroplasticity. Researchers have developed innovative techniques to observe the brain in real-time, allowing for a better understanding of how neurons communicate and form networks. These discoveries have significant implications for treating neurological disorders such as Alzheimer's disease, Parkinson's disease, and epilepsy. Moreover, studies on neuroplasticity have shown the brain's remarkable ability to adapt and reorganize itself, which could lead to new rehabilitation methods for stroke and injury patients. The integration of artificial intelligence with neuroscience is also paving the way for more precise and personalized treatments. These advancements are not only revolutionizing medical practices but also enhancing our understanding of human cognition, emotions, and behavior. As a result, the potential for improving mental health treatments and developing cognitive-enhancing technologies is immense. Ongoing research is focused on decoding the complex neural networks that underlie our thoughts and actions, promising to unlock further mysteries of the brain.",
    summary:
      "Exploring the latest neuroscience discoveries and their implications.",
    author: "Bob Smith",
    publishDate: "2024-02-10",
    category: ["Health", "Neuroscience"],
    trending: true,
    image:
      "https://images.pexels.com/photos/7089020/pexels-photo-7089020.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    likes: 1200,
    views: 5000,
  },
  {
    title: "The Rise of Quantum Computing",
    content:
      "Quantum computing is poised to revolutionize technology with its unparalleled processing power. Unlike classical computers that use bits, quantum computers use qubits, which can represent multiple states simultaneously. This capability allows quantum computers to solve complex problems at speeds unimaginable with current technology. Industries such as cryptography, material science, and drug discovery are expected to be transformed by quantum computing. Researchers are making strides in overcoming the challenges of qubit stability and error correction, bringing us closer to practical and scalable quantum computers. The potential applications of quantum computing are vast, promising breakthroughs in various fields. Additionally, quantum computing is expected to significantly impact financial modeling, climate forecasting, and artificial intelligence, offering solutions to problems that are currently computationally infeasible. As companies and governments invest heavily in quantum research, the race to achieve quantum supremacy is intensifying, marking the beginning of a new era in computation.",
    summary:
      "An overview of quantum computing and its potential impacts on technology.",
    author: "Alice Johnson",
    publishDate: "2024-01-15",
    category: ["Tech", "AI"],
    trending: true,
    image:
      "https://images.pexels.com/photos/6476254/pexels-photo-6476254.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    likes: 1800,
    views: 7500,
  },
  {
    title: "How AI is Transforming Healthcare",
    content:
      "Artificial intelligence is making significant strides in healthcare, from diagnostics to treatment. AI algorithms can analyze vast amounts of medical data quickly, identifying patterns and making predictions that aid in early diagnosis and personalized treatment plans. In radiology, AI tools are assisting doctors in detecting diseases such as cancer with greater accuracy. Moreover, AI-powered robots are being used in surgeries, enhancing precision and reducing recovery times. The integration of AI in healthcare is also improving patient management systems, leading to more efficient and effective care. As AI technology continues to evolve, its impact on healthcare is expected to grow exponentially. The use of AI in genomics is also noteworthy, enabling the identification of genetic markers for various diseases and guiding the development of targeted therapies. Furthermore, AI-driven predictive analytics are helping hospitals manage resources more effectively, optimizing patient flow and reducing wait times. The potential for AI in healthcare extends to mental health, where machine learning algorithms are being used to detect early signs of conditions such as depression and anxiety, offering new avenues for timely intervention and support.",
    summary: "The role of AI in revolutionizing healthcare practices.",
    author: "Catherine Lee",
    publishDate: "2024-03-05",
    category: ["Tech", "Health", "AI"],
    image:
      "https://images.pexels.com/photos/267350/pexels-photo-267350.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    likes: 2000,
    views: 8200,
  },
  {
    title: "The Future of Neural Interfaces",
    content:
      "Neural interfaces are becoming more sophisticated, offering new ways to interact with technology. These interfaces connect the human brain directly to external devices, enabling communication and control through thought alone. Advances in this field have led to the development of brain-computer interfaces (BCIs) that can assist individuals with disabilities, allowing them to control prosthetic limbs or communicate via computer systems. Research is also exploring the potential of neural interfaces in enhancing cognitive abilities and treating neurological disorders. As technology progresses, neural interfaces will likely become more integrated into daily life, offering new possibilities for human-machine interaction and enhancing our understanding of the brain's capabilities.",
    summary: "Advancements in neural interfaces and their future applications.",
    author: "David Brown",
    publishDate: "2024-03-20",
    category: ["Tech", "Neuroscience", "Innovation"],
    image:
      "https://images.pexels.com/photos/1772123/pexels-photo-1772123.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    likes: 1500,
    views: 6000,
  },
  {
    title: "Startup Ecosystems in Emerging Markets",
    content:
      "Emerging markets are witnessing a surge in startup activity, driven by innovation and investment. Countries in Asia, Africa, and Latin America are becoming hotspots for entrepreneurial growth, with local startups addressing unique regional challenges. These ecosystems are benefiting from increased access to funding, improved infrastructure, and supportive government policies. Additionally, the rise of digital platforms and mobile technologies is enabling startups to scale rapidly and reach wider audiences. As these markets continue to develop, they are expected to play a significant role in the global startup landscape, offering new opportunities for investors and entrepreneurs alike.",
    summary: "The growth of startup ecosystems in developing regions.",
    author: "Emma Wilson",
    publishDate: "2024-04-01",
    category: ["Tech", "Startup", "Economy"],
    image:
      "https://images.pexels.com/photos/2653362/pexels-photo-2653362.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    likes: 1700,
    views: 6800,
  },
  {
    title: "Tech Innovations in Renewable Energy",
    content:
      "Renewable energy technologies are evolving rapidly, promising a sustainable future. Innovations in solar, wind, and battery storage are making renewable energy more efficient and cost-effective. Breakthroughs in photovoltaic materials and energy storage solutions are addressing the intermittency issues associated with renewable sources. Additionally, advancements in smart grid technology are enhancing the integration of renewable energy into existing power systems. As global efforts to combat climate change intensify, the role of renewable energy is becoming increasingly crucial. These innovations are not only reducing carbon footprints but also creating new economic opportunities and driving technological progress.",
    summary: "The latest innovations in renewable energy technology.",
    author: "Frank Martinez",
    publishDate: "2024-04-15",
    category: ["Tech", "Environment", "Sustainability"],
    trending: true,
    image:
      "https://images.pexels.com/photos/257923/pexels-photo-257923.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    likes: 1900,
    views: 7200,
  },
  {
    title: "Understanding the Human Brain: New Research",
    content:
      "Recent studies have provided deeper insights into the complexities of the human brain. Advanced imaging techniques and computational models are revealing the intricate workings of neural networks and brain regions. This research is enhancing our understanding of cognitive functions, such as memory, learning, and decision-making. Moreover, it is shedding light on the mechanisms underlying various neurological and psychiatric disorders. By uncovering the brain's fundamental principles, scientists are paving the way for novel therapeutic strategies and interventions. The ongoing exploration of brain function is crucial for developing treatments that can improve mental health and cognitive performance.",
    summary: "A look at new research in neuroscience and brain function.",
    author: "Grace Kim",
    publishDate: "2024-05-05",
    category: ["Health", "Neuroscience"],
    image:
      "https://images.pexels.com/photos/15410078/pexels-photo-15410078/free-photo-of-brain-model-on-plate.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    likes: 1600,
    views: 6500,
  },
  {
    title: "AI Startups to Watch in 2024",
    content:
      "Several AI startups are making headlines with their groundbreaking technologies and solutions. These startups are leveraging machine learning, natural language processing, and computer vision to create innovative products and services. From healthcare and finance to education and entertainment, AI startups are addressing a wide range of challenges and opportunities. Their cutting-edge technologies are not only enhancing existing industries but also creating new markets. As these startups continue to grow and attract investment, they are poised to make significant contributions to the AI landscape. Keeping an eye on these emerging companies can provide valuable insights into the future of artificial intelligence.",
    summary: "A review of promising AI startups in 2024.",
    author: "Henry Adams",
    publishDate: "2024-05-15",
    category: ["Tech", "AI", "Startup"],
    trending: true,
    image:
      "https://images.pexels.com/photos/6476254/pexels-photo-6476254.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    likes: 2100,
    views: 8100,
  },
  {
    title: "The Impact of Neuroscience on Education",
    content:
      "Neuroscience is providing valuable insights into how we learn and retain information. These findings are being used to develop more effective teaching methods and educational tools. Understanding the brain's learning processes can help educators tailor their approaches to different learning styles and needs. Techniques such as neurofeedback and brain stimulation are also being explored to enhance learning and memory. As research continues, the integration of neuroscience into education promises to revolutionize how we teach and learn.",
    summary:
      "Exploring the role of neuroscience in improving educational outcomes.",
    author: "Sarah Johnson",
    publishDate: "2024-06-01",
    category: ["Health", "Neuroscience", "Education"],
    trending: true,
    image:
      "https://images.unsplash.com/photo-1621560222298-0824faf9add5?q=80&w=1769&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    likes: 1400,
    views: 5500,
  },
  {
    title: "The Evolution of Aviation Technology",
    content:
      "Aviation technology is rapidly evolving, with advancements in aircraft design, materials, and propulsion systems. These innovations are making air travel more efficient, safer, and environmentally friendly. The development of electric and hybrid-electric aircraft is particularly promising, offering the potential to reduce carbon emissions significantly. Additionally, improvements in avionics and air traffic management systems are enhancing the safety and efficiency of air travel. As technology continues to progress, the future of aviation looks set to be more sustainable and advanced than ever.",
    summary: "An overview of the latest advancements in aviation technology.",
    author: "John Miller",
    publishDate: "2024-06-15",
    category: ["Tech", "Aviation", "Sustainability"],
    image:
      "https://images.unsplash.com/flagged/photo-1579750481098-8b3a62c9b85d?q=80&w=1760&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    likes: 1800,
    views: 6800,
  },
  {
    title: "Startups Leveraging Blockchain Technology",
    content:
      "Blockchain technology is being leveraged by startups to create innovative solutions across various industries. From finance and supply chain to healthcare and entertainment, blockchain is enabling more secure, transparent, and efficient processes. Startups are using blockchain to create decentralized applications (dApps) that can disrupt traditional business models. The technology's ability to provide immutable records and smart contracts is particularly valuable in industries where trust and verification are crucial. As blockchain technology continues to mature, its adoption by startups is expected to increase, driving further innovation and transformation.",
    summary:
      "How startups are using blockchain technology to drive innovation.",
    author: "Laura Davis",
    publishDate: "2024-07-01",
    category: ["Tech", "Startup", "Blockchain"],
    image:
      "https://images.unsplash.com/photo-1520437358207-323b43b50729?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    likes: 1700,
    views: 7000,
  },
  {
    title: "The Role of AI in Autonomous Vehicles",
    content:
      "AI is playing a crucial role in the development of autonomous vehicles. Machine learning algorithms and computer vision are enabling these vehicles to navigate complex environments, recognize objects, and make real-time decisions. The progress in AI technology is bringing us closer to the widespread adoption of self-driving cars, which promise to improve road safety, reduce traffic congestion, and provide greater mobility for individuals with disabilities. However, challenges such as regulatory issues, ethical considerations, and technological hurdles remain. As research and development continue, the integration of AI in autonomous vehicles is expected to revolutionize transportation.",
    summary:
      "An examination of how AI is advancing autonomous vehicle technology.",
    author: "Michael Thompson",
    publishDate: "2024-07-15",
    category: ["Tech", "AI", "Autonomous Vehicles"],
    image:
      "https://images.unsplash.com/photo-1531297484001-80022131f5a1?q=80&w=1720&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    likes: 2000,
    views: 7800,
  },
  {
    title: "Neuroscience and the Future of Brain Health",
    content:
      "Neuroscience is at the forefront of understanding and improving brain health. New research is uncovering the factors that contribute to brain aging and neurodegenerative diseases. By identifying these factors, scientists are developing strategies to promote brain health and prevent cognitive decline. This includes lifestyle interventions, pharmacological treatments, and cognitive training programs. The integration of neurotechnology, such as brain stimulation devices, is also showing promise in enhancing brain function. As the population ages, the importance of maintaining brain health becomes increasingly critical, making neuroscience research more relevant than ever.",
    summary:
      "Advances in neuroscience aimed at improving brain health and preventing cognitive decline.",
    author: "Natalie Green",
    publishDate: "2024-08-01",
    category: ["Health", "Neuroscience"],
    image:
      "https://images.unsplash.com/photo-1605810230434-7631ac76ec81?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    likes: 1500,
    views: 6400,
  },
  {
    title: "Neuroscience Breakthroughs in 2024",
    content:
      "Recent advancements in neuroscience have uncovered new insights into brain function and neuroplasticity. Researchers have developed innovative techniques to observe the brain in real-time, allowing for a better understanding of how neurons communicate and form networks. These discoveries have significant implications for treating neurological disorders such as Alzheimer's disease, Parkinson's disease, and epilepsy. Moreover, studies on neuroplasticity have shown the brain's remarkable ability to adapt and reorganize itself, which could lead to new rehabilitation methods for stroke and injury patients. The integration of artificial intelligence with neuroscience is also paving the way for more precise and personalized treatments. These advancements are not only revolutionizing medical practices but also enhancing our understanding of human cognition, emotions, and behavior. As a result, the potential for improving mental health treatments and developing cognitive-enhancing technologies is immense. Ongoing research is focused on decoding the complex neural networks that underlie our thoughts and actions, promising to unlock further mysteries of the brain.",
    summary:
      "Exploring the latest neuroscience discoveries and their implications.",
    author: "Bob Smith",
    publishDate: "2024-02-10",
    category: ["Health", "Neuroscience"],
    trending: true,
    image:
      "https://images.pexels.com/photos/7089020/pexels-photo-7089020.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    likes: 1200,
    views: 5000,
  },
  {
    title: "The Rise of Quantum Computing",
    content:
      "Quantum computing is poised to revolutionize technology with its unparalleled processing power. Unlike classical computers that use bits, quantum computers use qubits, which can represent multiple states simultaneously. This capability allows quantum computers to solve complex problems at speeds unimaginable with current technology. Industries such as cryptography, material science, and drug discovery are expected to be transformed by quantum computing. Researchers are making strides in overcoming the challenges of qubit stability and error correction, bringing us closer to practical and scalable quantum computers. The potential applications of quantum computing are vast, promising breakthroughs in various fields. Additionally, quantum computing is expected to significantly impact financial modeling, climate forecasting, and artificial intelligence, offering solutions to problems that are currently computationally infeasible. As companies and governments invest heavily in quantum research, the race to achieve quantum supremacy is intensifying, marking the beginning of a new era in computation.",
    summary:
      "An overview of quantum computing and its potential impacts on technology.",
    author: "Alice Johnson",
    publishDate: "2024-01-15",
    category: ["Tech", "AI"],
    trending: true,
    image:
      "https://images.pexels.com/photos/6476254/pexels-photo-6476254.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    likes: 1800,
    views: 7500,
  },
  {
    title: "How AI is Transforming Healthcare",
    content:
      "Artificial intelligence is making significant strides in healthcare, from diagnostics to treatment. AI algorithms can analyze vast amounts of medical data quickly, identifying patterns and making predictions that aid in early diagnosis and personalized treatment plans. In radiology, AI tools are assisting doctors in detecting diseases such as cancer with greater accuracy. Moreover, AI-powered robots are being used in surgeries, enhancing precision and reducing recovery times. The integration of AI in healthcare is also improving patient management systems, leading to more efficient and effective care. As AI technology continues to evolve, its impact on healthcare is expected to grow exponentially. The use of AI in genomics is also noteworthy, enabling the identification of genetic markers for various diseases and guiding the development of targeted therapies. Furthermore, AI-driven predictive analytics are helping hospitals manage resources more effectively, optimizing patient flow and reducing wait times. The potential for AI in healthcare extends to mental health, where machine learning algorithms are being used to detect early signs of conditions such as depression and anxiety, offering new avenues for timely intervention and support.",
    summary: "The role of AI in revolutionizing healthcare practices.",
    author: "Catherine Lee",
    publishDate: "2024-03-05",
    category: ["Tech", "Health", "AI"],
    image:
      "https://images.pexels.com/photos/267350/pexels-photo-267350.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    likes: 2000,
    views: 8200,
  },
  {
    title: "The Future of Neural Interfaces",
    content:
      "Neural interfaces are becoming more sophisticated, offering new ways to interact with technology. These interfaces connect the human brain directly to external devices, enabling communication and control through thought alone. Advances in this field have led to the development of brain-computer interfaces (BCIs) that can assist individuals with disabilities, allowing them to control prosthetic limbs or communicate via computer systems. Research is also exploring the potential of neural interfaces in enhancing cognitive abilities and treating neurological disorders. As technology progresses, neural interfaces will likely become more integrated into daily life, offering new possibilities for human-machine interaction and enhancing our understanding of the brain's capabilities.",
    summary: "Advancements in neural interfaces and their future applications.",
    author: "David Brown",
    publishDate: "2024-03-20",
    category: ["Tech", "Neuroscience", "Innovation"],
    image:
      "https://images.pexels.com/photos/1772123/pexels-photo-1772123.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    likes: 1500,
    views: 6000,
  },
  {
    title: "Startup Ecosystems in Emerging Markets",
    content:
      "Emerging markets are witnessing a surge in startup activity, driven by innovation and investment. Countries in Asia, Africa, and Latin America are becoming hotspots for entrepreneurial growth, with local startups addressing unique regional challenges. These ecosystems are benefiting from increased access to funding, improved infrastructure, and supportive government policies. Additionally, the rise of digital platforms and mobile technologies is enabling startups to scale rapidly and reach wider audiences. As these markets continue to develop, they are expected to play a significant role in the global startup landscape, offering new opportunities for investors and entrepreneurs alike.",
    summary: "The growth of startup ecosystems in developing regions.",
    author: "Emma Wilson",
    publishDate: "2024-04-01",
    category: ["Tech", "Startup", "Economy"],
    image:
      "https://images.pexels.com/photos/2653362/pexels-photo-2653362.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    likes: 1700,
    views: 6800,
  },
  {
    title: "Tech Innovations in Renewable Energy",
    content:
      "Renewable energy technologies are evolving rapidly, promising a sustainable future. Innovations in solar, wind, and battery storage are making renewable energy more efficient and cost-effective. Breakthroughs in photovoltaic materials and energy storage solutions are addressing the intermittency issues associated with renewable sources. Additionally, advancements in smart grid technology are enhancing the integration of renewable energy into existing power systems. As global efforts to combat climate change intensify, the role of renewable energy is becoming increasingly crucial. These innovations are not only reducing carbon footprints but also creating new economic opportunities and driving technological progress.",
    summary: "The latest innovations in renewable energy technology.",
    author: "Frank Martinez",
    publishDate: "2024-04-15",
    category: ["Tech", "Environment", "Sustainability"],
    trending: true,
    image:
      "https://images.pexels.com/photos/257923/pexels-photo-257923.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    likes: 1900,
    views: 7200,
  },
  {
    title: "Understanding the Human Brain: New Research",
    content:
      "Recent studies have provided deeper insights into the complexities of the human brain. Advanced imaging techniques and computational models are revealing the intricate workings of neural networks and brain regions. This research is enhancing our understanding of cognitive functions, such as memory, learning, and decision-making. Moreover, it is shedding light on the mechanisms underlying various neurological and psychiatric disorders. By uncovering the brain's fundamental principles, scientists are paving the way for novel therapeutic strategies and interventions. The ongoing exploration of brain function is crucial for developing treatments that can improve mental health and cognitive performance.",
    summary: "A look at new research in neuroscience and brain function.",
    author: "Grace Kim",
    publishDate: "2024-05-05",
    category: ["Health", "Neuroscience"],
    image:
      "https://images.pexels.com/photos/15410078/pexels-photo-15410078/free-photo-of-brain-model-on-plate.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    likes: 1600,
    views: 6500,
  },
  
];

export const data = NewData.map((element) => {
  return {
    ...element,
    id: generate(),
    content:element.content+lorem()
  };
});






export const fixed = (num) => {
  if (num > 1000 && num <= 1000000) {
    return (num / 1000).toFixed(1).replace(".0", "") + "k";
  }
  if (num > 1000000 && num <= 1000000000) {
    return (num / 1000000).toFixed(1).replace(".0", "") + "M";
  }
  if (num > 1000000000 && num <= 1000000000000) {
    return (num / 1000000000).toFixed(1).replace(".0", "") + "B";
    }
};

export const Time = (date) => {
  const publish = new Date(date);
  const options = { year: "numeric", month: "long", day: "numeric" };
  const finalDate = publish.toLocaleDateString(undefined, options);
  
  return finalDate;
  };
  
  

  export const interval=data.map((element,key)=>{
         const id= element.id
         const viewsArr=[] 
         if(key<=20){
           
            for(let i=1;i<=10;i++){
              const date= new Date()
              date.setDate(date.getDate()-i)
              const viewsMath= Math.round(element.views/10)
              viewsArr.push({date:Time(date), views:viewsMath})
            }
          return {id,views:viewsArr}
         }
          else if(key>20){

           return {id:id, views:viewsArr}
           
         }        
  })




