import React, { useState } from 'react'
import css from "./Home.module.css"
import image from "../../Images/pexels-pixabay-221011.jpg"
import { NavLink } from 'react-router-dom'
import Cards from '../../Components/Cards/Cards'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const Home = ({storage}) => {
const colour= useSelector((state)=>state.storage)
const dispatch= useDispatch()
  useEffect(() => {
    dispatch({type:"storenon"})
    const storedData = storage.getItem("user");
    if (!storedData) {
      storage.setItem("user", JSON.stringify(colour));
    }
}, [])
   

  return (
    <div className={css.container}>
          <div className={css.top}>
            <div className={css.left}>
                 <div className={css.increment}>
                    <span>ContentCloud</span>
                    <span>Start the Journey of learning and sharing the ideas of exploring. With each new word get access to the world of language and knowledge together.</span>
                 </div>
                 <div className={css.posterior}>
                     <NavLink to="/explore" className={css.button}>Start Exploring</NavLink>
                 </div>
            </div>
            <div className={css.right}>
                <div className={css.image}>

                <img src={image} alt="" />
                </div>
            </div>
          </div>
          <div className={css.middle}>
                <Cards/>
          </div>
          <div className={css.lower}>

          </div>

    </div>
  )
}

export default Home