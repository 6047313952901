import React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import css from "./Explore.module.css"
import Cards from '../../Components/Cards/Cards'
import Card from '../../Components/Card/Card'
import { useLocation } from 'react-router-dom'
import { MdQuestionMark } from "react-icons/md";



const Explore = ({storage}) => {
  const location = useLocation();
  const colour= useSelector((state)=>state.storage)
  const dispatch= useDispatch()


 const select= useSelector((state)=>state.data)
 const val=useSelector((state)=>state.value)

 
 
 
 
 const [data, setData] = useState(select);
 
 useEffect(() => {
   dispatch({type:"black"})
   dispatch({type:"store"})
   dispatch({ type: "explore" });
   //  dispatch({type:"nav"})
  }, [location ]);
  
  useEffect(() => {
   dispatch({type:"store"})
  }, [])
 useEffect(() => {
   setData(select);
 }, [select]);


 


 useEffect(() => {
  if (val && val.trim() !== "") {
    const lowercaseValue = val.trim().toLowerCase();
    const filteredData = select.filter((element) => {
      return element.title.trim().toLowerCase().includes(lowercaseValue) || 
             element.category.some((category) => 
               category.trim().toLowerCase() === lowercaseValue
             ) || element.author.trim().toLowerCase().includes(lowercaseValue);
    });
     setData(filteredData);
   } else {
     setData(select);
   }
 }, [val, select]);

 return (
   <div className={css.container}>  
    <div className={css.main}>
   {val&&<div>Showing Results for: {val}</div>}
           
             {!val&&<div>Explore</div>}
    </div>

     <div className={css.body}>
      <div className={css.cardMain}>

{ data && data.map((element) => (
  
  
  <Card  key={element.id} element={element} />
  
))}
      {data.length===0&&<div>Are you sure: <MdQuestionMark />: No results for {val}</div>
}
</div>
      </div>
   </div>
 );
};

export default Explore;
