import React, { useEffect } from "react";
import css from "./Layout.module.css";
import { NavLink, Navigate, Outlet, useLocation } from "react-router-dom";
import { useState } from "react";
import Explore from "../Explore/Explore";
import { useNavigate } from "react-router-dom";
import { RxCross1 } from "react-icons/rx";
import { useSelector, useDispatch } from "react-redux";

const Layout = ({storage}) => {
  const location= useLocation()

  const [initialized, setInitialized] = useState(false);
  const navigate = useNavigate();
  const [selector, setSelector] = useState(false)
// const [store, setStore] = useState(false)




const store= useSelector((state)=>state.store)
const val= useSelector((state)=>state.value)
const navu= useSelector((state)=>state.nav)
const colour= useSelector((state)=>state.storage)


  const dispatch= useDispatch()
//  const storage= localStorage  


  
  useEffect(() => {
    const gotStorage = JSON.parse(storage.getItem("user"));
    if (gotStorage && gotStorage.type === "black") {
      // setStore(true);
      dispatch({type:"store"})
      console.log("black");
      console.log(store)
    } else{
      // setStore(false); // Set store to false when the storage is not black
      dispatch({type:"storenon"})
      console.log("not black");
    }
  }, [selector,storage]);
  
  const handleClick = (e) => {
    if(val){

      e.preventDefault();
      navigate("/explore");       
      // dispatch({type:"nav"})
      // setSelector(false)
      setSelector(true)
    }
    else{
      e.preventDefault()
    }
  };

  
useEffect(() => {

   if(selector){
      // dispatch({type:"nav"})
      //  gotColour.type="black"
      dispatch({type:"black"})
     
      storage.setItem("user", JSON.stringify(colour))
      console.log("the selector is ok")
    }
   else{
    // dispatch({type:"navnon"})
  //  gotColour.type="white"
  dispatch({type:"white"})
     
      storage.setItem("user", JSON.stringify(colour))
      console.log("I am there")
  }

}, [selector])



  const changed=(e)=>{
    dispatch({type:"value",payload:e.target.value})
    setInitialized(true)
  
  }

  useEffect(() => {
         if(!val){
          setInitialized(false)
         }
  }, [val])
  

  const clicked=()=>{
          dispatch({type:"valnon"})
  }

 const Explore=()=>{
       dispatch({type:"valnon"})
        setSelector(true)
 }

  return (
    
    <div className={css.layout}>
      {location.pathname==="/" && <Navigate to="/home"/>}
      {/* <div className={css.header} > */}
      {/* <div className={`${navu?css.contain:css.header}`}> */}
      <div className={`${store?css.contain:css.header}`}>
        <div className={css.left} >
          <div className={css.logo}>
            <NavLink  onClick={()=>{setSelector(false)}} to="/" className={css.span}>
              ContentCloud
            </NavLink>
          </div>
          <NavLink to="/home" onClick={()=>{setSelector(false)}}  className={`${store?css.spaner:css.span}`}>
            Home
          </NavLink>
          <NavLink to="/about" onClick={()=>{setSelector(false)}} className={`${store?css.spaner:css.span}`}>
            About
          </NavLink>
          <NavLink onClick={Explore} to="/explore"  className={`${store?css.spaner:css.span}`}>
            Explore
          </NavLink>
        </div>


        <div className={css.right}>
          <form className={css.filter}  onSubmit={handleClick}>
            <div className={css.input}>
            <input type="text" value={val} onChange={changed}/>
            {
             initialized?
              <RxCross1 onClick={clicked}/>:null
            }

            </div>
            {/* <NavLink to="/explore" type="" className={css.button} onClick={()=>{handleClick()}}>Search</NavLink> */}
            <button type="submit" className={css.button}>Search</button>
          </form>
          <div className={css.clickers}>
            <button>Sign in</button>
            <button>Login</button>
          </div>
        </div>
      </div>
      <div className={css.content}>
        <div className={css.outlet}>
          <Outlet/>
        </div>
      </div>
      <div className={css.footer}>
        <div className={css.extreme}>
          <div className={css.top}>
            <span>ContentCloud</span>
            <span>Your choice for getting informed</span>
          </div>
          <div className={css.copy}>

          <span>Copyright &#169; ContentCloud 2024</span>
          </div>
        </div>

        <div className={css.supreme}>
          <div className={css.right}>
          <span>Connect with us!!!</span>
          </div>
          <div className={css.wing}>

          <span>Youtube</span>
          <span>Facebook</span>
          <span>Instagram</span>
          <span>Twitter</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
