import { Time, data } from "./Data";
import { interval } from "./Data";

const initialData = {
  data: data,
  value: "",
  store: false,
  storage: {
    type: "white",
  },
  interval: interval,
};

const reducer = (state = initialData, action) => {
  switch (action.type) {
    case "trending":
      return {
        ...state,
        data: state.data.filter((element) => element.trending === true),
      };
    case "like_increment":
      const updatedData = state.data.map((element) =>
        element.id === action.payload
          ? { ...element, likes: element.likes + 1 }
          : element
      );
      return {
        ...state,
        data: updatedData,
      };

    case "liked_decrement":
      return {
        ...state,
        data: state.data.map((element) =>
          element.id === action.payload
            ? { ...element, likes: element.likes - 1 }
            : element
        ),
      };
    case "explore":
      return {
        ...state,
        data: initialData.data,
      };

    case "value":
      return {
        ...state,
        value: action.payload,
      };
    case "valnon":
      return {
        ...state,
        value: "",
      };
    case "store":
      return {
        ...state,
        store: true,
      };

    case "storenon":
      return {
        ...state,
        store: false,
      };

    case "black":
      return {
        ...state,
        storage: { type: "black" },
      };
    case "white":
      return {
        ...state,
        storage: { type: "white" },
      };

    case "views":

      return {
        ...state,
        data: state.data.map((element) =>
          element.id===action.payload?{...element, views:element.views+1}:element
        ),
      };

    // case "views":
    //   const updatedElement = state.data
    //     .filter((element) => element.id === action.payload)
    //     .map((element) => ({ ...element, views: element.views + 1 }));

    //   return {
    //     ...state,
    //     data: state.data.map((element) =>
    //       element.id === action.payload ? updatedElement[0] : element
    //     ),
    //   };

case "dateViews":
  const date = new Date();
  const today = Time(date);
 
  if (state.interval) {
    return {
      ...state,
      interval: state.interval.map((element) => {
        if (element.id === action.payload) {
    
          return {
            ...element,
            views: element.views
              ? element.views.map((look) => {
           
                  if (look.date === today) {
                    return { ...look, views: look.views + 1 };
                  }
                  return look;
                }).concat(
                  element.views.some(
                    (element) => element.date === today
                  )
                    ? []
                    : [{ date: today, views: 1 }]
                )
              :  [{ date: today, views: 1 }]
          };
        }
        return element;
      }),
    };
  } else {
    console.log("I got no interval");
    return state;
  }


    default:
      return state;
  }
};

export default reducer;
